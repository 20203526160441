<template>
    <content-view>
        <documents-view/>
    </content-view>
</template>

<script>
    import ContentView from '@/views/menu/ContentView';
    import DocumentsView from '@/components/document/list/Content';
    import { metaTitle } from '@/mixins/meta_title';

    export default {
        name: 'Documents',
        mixins: [metaTitle],
        components: { ContentView, DocumentsView }
    };
</script>

<style lang="scss">
    @import '#sass/_variables.sass';
    @import '#sass/_mixins.sass';

    .doc-base {
        &__back {
            fill: #cacbcd;
            color: #cfcfcf;
            display: flex;
            align-items: center;
            margin: 0px 0px 10px;
            svg {
                width: 12px;
                height: 12px;
                margin-right: 5px;
                display: inline-block;
            }

            @include above(476px) {
                display: none;
            }
        }
    }
</style>

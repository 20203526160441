<template>
    <div class="v-modal download-excel">
        <div class="modal-content">
            <div class="modal-header download-excel__header">
                <h3 class="block-title">
                    Выберите категорию для создания отчёта в виде таблицы Excel.
                </h3>

                <div class="block-options close-question-form-wrap">
                    <button
                        type="button"
                        class="btn-block-option close-question-form close-modal"
                        @click="$modal.hide('DownloadExcelModal')"
                    >
                        ×
                    </button>
                </div>
            </div>

            <div class="modal-body download-excel__body">
                <ValidationObserver
                    tag="form"
                    ref="observerQuestion"
                    class="download-excel__from without_double_block"
                    @submit.prevent="onSubmit"
                >
                  <div class="row">
                      <div class="col-12 col-md-3">
                        <div class="form-radio">
                          <label class="download-excel__control css-control">
                            <input
                                type="radio"
                                name="type"
                                class="css-control-input"
                                value="user"
                                v-model="type"
                            >
                            <span class="css-control-indicator"></span>
                            Пользователь
                          </label>
                        </div>
                      </div>

                      <div class="col-12 col-md-9 form-group">
                          <v-select2
                              :class="['download-excel__select', {'is-disabled': type !== 'user'}]"
                              aria-selected="true"
                              placeholder="Выберите пользователя"
                              label="full_name"
                              :clearable="false"
                              :options="listUsers"
                              :reduce="title => title.id"
                              :value="form.user"
                              @input="onSelected"
                          >
                              <span slot="no-options">Ничего не найдено</span>
                          </v-select2>
                      </div>
                  </div>
                    <div class="row">
                        <div class="col-12 col-md-3">
                          <div class="form-radio">
                            <label class="download-excel__control css-control">
                              <input
                                  type="radio"
                                  name="type"
                                  class="css-control-input"
                                  value="document"
                                  v-model="type"
                              >
                              <span class="css-control-indicator"></span>
                              Документ
                            </label>
                          </div>
                        </div>
                        <div class="col-12 col-md-9 form-group">
                            <v-select2
                                :class="['download-excel__select', {'is-disabled': type !== 'document'}]"
                                aria-selected="true"
                                placeholder="Выберите Документ"
                                label="name"
                                :clearable="false"
                                :options="listDocuments"
                                :reduce="title => title.id"
                                :value="form.document"
                                @input="onSelected"
                            >
                                <span slot="no-options">Ничего не найдено</span>
                            </v-select2>
                        </div>
                    </div>

                    <div class="download-excel__footer modal-footer">
                        <button
                            class="btn btn-rounded btn-primary-dark"
                            type="submit"
                            :disabled="!form[type]"
                        >
                            Скачать
                        </button>

                        <button
                            class="btn btn-rounded btn-alt-secondary"
                            type="button"
                            @click="$modal.hide('DownloadExcelModal')"
                        >
                            Отмена
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: 'DownloadExcelModal',

        data() {
            return {
              type: 'user',
              form: {
                  user: null,
                  document: null
              },
              listUsers: [],
              listDocuments: []
            };
        },

        methods: {
            onSelected(value) {
                const prevSelected = this.type === 'document' ? 'user' : 'document';

                this.form[this.type] = value;
                this.form[prevSelected] = null;
            },

            onSubmit() {
                this.$modal.hide('DownloadExcelModal', {
                    type: this.type,
                    value: this.form[this.type]
                });
            }
        },

        async created() {
            await Promise.all([
                session.get('/api/v1/users/all_is_active/'),
                session.get('/api/v1/document/all_list/')
            ])
            .then(([res1, res2]) => {
                this.listUsers = res1.data;
                this.listDocuments = res2.data;
            })
            .catch((error) => {
                console.error(error);
            });
        }
    };
</script>

<style lang="scss">
    [data-modal="DownloadExcelModal"] {
        .v {
            &--modal-box {
                overflow: visible;
            }
        }
    }

    .download-excel {
        &__footer {
            margin-top: 20px;
            justify-content: center;
        }

        &__control {
            white-space: nowrap;
        }

        &__select {
            cursor: pointer;
            .vs {
                &__dropdown-toggle {
                    max-height: unset;
                }

                &__selected {
                    position: relative;
                }
            }

            &.is-disabled {
              opacity: .5;
              pointer-events: none;
            }
        }
    }
</style>

<template>
    <div class="doc-base-caption">
        <span
            class="doc-base-caption__name"
            :title="captionData.name"
            @click="$emit('following', captionData)"
        >
            {{ captionData.name }}
        </span>

        <v-popover
            v-if="captionData.is_access_edit"
            popoverInnerClass="one-rubric__popover-inner"
            popoverClass="one-rubric__popover"
            placement="bottom-center"
            offset="1"
        >
            <span
                class="doc-base-caption__more"
                title="Ещё"
                aria-label="More"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4">
                    <path
                        fill-rule="evenodd"
                        d="M4 2a2 2 0 10-4 0 2 2 0 004 0zM11 2a2 2 0 10-4 0 2 2 0 004 0zM18 2a2 2 0 10-4 0 2 2 0 004 0z"
                        clip-rule="evenodd"
                    />
                </svg>
            </span>

            <div class="menu-btn__inner" slot="popover">
                <a
                    href="javascript:void(0)"
                    class="menu-btn__button one-rubric__change"
                    v-close-popover
                    @click="$emit('edit', captionData)"
                >
                    Редактировать
                </a>

                <button
                    class="menu-btn__button one-rubric__delete"
                    type="button"
                    v-close-popover
                    @click="$emit('delete', captionData)"
                >
                    Удалить
                </button>
            </div>
        </v-popover>
    </div>
</template>

<script>
    export default {
        name: 'CaptionDocument',
        props: {
            captionData: {
                type: Object,
                default: () => {}
            }
        }
    };
</script>

<style lang="scss">
    .doc-base {
        &-caption {
            $bn: &;

            display: flex;
            align-items: center;
            &__name {
                cursor: pointer;
                color: #999999;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: normal;
                letter-spacing: 1.3px;
                overflow: hidden;
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                &:hover {
                    color: #79a3e2;
                }
            }

            &__more {
                fill: #999999;
                cursor: pointer;
                margin-left: 10px;
                &:hover {
                    fill: #ffb71c;
                }
            }

            &--sub {
                #{$bn} {
                    &__name {
                        color: #878787;
                        font-size: 18px;
                        &:hover {
                            color: #79a3e2;
                        }
                    }
                }
            }
        }
    }
</style>
